import {Grid} from "@mui/material";
import EpgTz from "./EpgTz";
import EpgShowTime from "./EpgShowTime";
import * as React from "react";
import NoEpgButton from "./NoEpgButton";
import {useFileUrlContext} from "../../context/EpgViewerContext";

// this is the part that holds Epg Time Zone, Time Now, No EPG switch
export default function Settings() {
    const {noEPG} = useFileUrlContext(); // needed in case we have to show NoEPG switch
    return (
        <Grid container justifyContent="center" alignItems="start">
            <Grid container spacing={1} sx={{width: '100vh', justifyContent: 'center'}}>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <EpgTz/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <EpgShowTime/>
                </Grid>
                {noEPG.length > 0 &&
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <NoEpgButton count={noEPG.length}/>
                    </Grid>}

            </Grid>
        </Grid>


    )
}
