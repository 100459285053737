import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import * as React from "react";
import {useMenuContext} from "../context/LayoutContext";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";

export default function TopBar() {
    let {open, setOpen} = useMenuContext();

    const drawerWidth = 220;

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (
        <Box name="TopBar" sx={{ flexGrow: 1}}>

            <AppBar open={open} >
                <Toolbar>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="overline" noWrap component="div" sx={{ flexGrow: 1 }}>
                        Epg Viewer
                    </Typography>
                        <Button disabled variant="contained">Login</Button>

                </Toolbar>
            </AppBar>
        </Box>
    );
}
