import moment from 'moment-timezone'
import timeZoneArr from "../js/TimeZoneArr";

// format and find timezone name from the array timeZoneArr
const FileSourceTz = (fileTz)  => {
    let tz = ''

    if(fileTz) {
        let rawTz = moment().utcOffset(fileTz).format('Z');
        tz = "File TZ: "+ timeZoneArr.find(o => o.offset === rawTz).label;
    }
    return tz;
}

export default FileSourceTz;
