import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useFileUrlContext} from "../../context/EpgViewerContext";
import {Divider} from "@mui/material";

export default function UpdateEpg() {

    const {updateEpg, setUpdateEpg, epgUrl, apiPostQuery, setApiPostQuery, sourceTz, displayTime} = useFileUrlContext();

    //update setApiPostQuery to re-fetch the EPG again with the new Tz and Time
    const handleClick = () => {
        const requestData = {epgUrl, file: '', sourceTz: sourceTz, displayTime: displayTime};
        setApiPostQuery(requestData);
        console.log(apiPostQuery)
        console.log(requestData)
        setUpdateEpg(false)
    }

    return(
        <Box  sx={{ display: 'flex', justifyContent: 'center', width: '100vh', height: 30, alignItems: 'center'}}>
            {updateEpg && <Divider sx={{ width: '80%'}}><Button size="small" onClick={handleClick} color="warning" variant="outlined">Update</Button></Divider>}
        </Box>
    )


}
