import React, {useState} from 'react';
import {CssBaseline, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useFileUrlContext} from "../../context/EpgViewerContext"
import Button from "@mui/material/Button";
import ErrorMsg from "./ErrorMsg";
import Box from "@mui/material/Box";


const UrlTab = () => {
    const {setEpgUrl, setApiPostQuery, sourceTz, displayTime} = useFileUrlContext();
    const [urlField, setUrlField] = useState('');
    const [errorURL, setErrorURL] = useState(false);

    function handleClick() {

        let apiQuery = {epgUrl: '', sourceTz, displayTime};

        //validate URL is correct
        const regex = new RegExp("^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$");
        // remove spaces
        const cleaURL = urlField.replace(/\s+/g, '');
        //check if URL ok
        if (regex.test(cleaURL)) {
            apiQuery.epgUrl = cleaURL
            setApiPostQuery(apiQuery);
            setEpgUrl(urlField);
            setErrorURL(false)
        } else {
            setErrorURL(true) //show error if URL is wrong
        }
    }

    return (
        <div>
            <CssBaseline>
                <Stack direction="row" spacing={2}>
                    <TextField required size="small" fullWidth id="outlined-required" label="EPG URL"
                               onChange={(e) => setUrlField(e.target.value)}/>
                    <Button variant="outlined" onClick={handleClick}>Submit</Button>
                </Stack>
                <Box> {errorURL && <ErrorMsg>Wrong URL. Try again by entering the correct URL</ErrorMsg>} </Box>
            </CssBaseline>
        </div>
    );
};

export default UrlTab;
