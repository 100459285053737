import React, {useEffect} from 'react';
import {Divider} from "@mui/material";
import {Chip, Collapse} from "@material-ui/core";
import Box from "@mui/material/Box";
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import Button from "@mui/material/Button";
import {useFileUrlContext} from "../../context/EpgViewerContext";
import Container from "@mui/material/Container";

// NoEPG channel ID's in Chips
const NoEpgShow = (props) => {

    const {showNoEPG, setShowNoEPG, epgData, noEPG, setErrorMsg} = useFileUrlContext();
    const noShows = props.noEpg;

    // if there are no Current Shows, open NoEPG channel by default and show error
    useEffect(() => {
        if (noEPG.length > 0 && epgData.length === 0) {
            setShowNoEPG(true)
            setErrorMsg('No Shows found')
        }
    });

    // close NoEPG
    function handleChange() {
        setShowNoEPG(false);
    }

    return (
        <Container maxWidth="lg" sx={{mt: 0, mb: 0}}>
            <Collapse in={showNoEPG}>
                <Button color="error" onClick={handleChange} sx={{display: 'flex', justifyContent: 'left'}} size="small"
                        variant="text" endIcon={<IndeterminateCheckBoxOutlinedIcon/>}>Channels without EPG</Button>
                <Divider/>
                <Box sx={{display: 'flex', m: 3, gap: 1, flexWrap: 'wrap', justifyContent: 'center'}}>
                    {noShows.map((item, i) => <Chip key={i} label={item}/> )}
                </Box>
            </Collapse>
        </Container>
    );
};

export default NoEpgShow;
