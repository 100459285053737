import React from 'react';
import Box from "@mui/material/Box";
import { IconButton } from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import {useFileUrlContext} from "../../context/EpgViewerContext";
import Container from "@mui/material/Container";

//Closing and Restart the whole EpgViewer
const CloseRestart = () => {
    let { setEpgUrl, setEpgData, setIsRestart, setErrorMsg, setNoEPG, setIsLoading, setFileTz } = useFileUrlContext();

    function restartEpgViewer() {
        setEpgUrl('');
        setEpgData([]);
        setNoEPG([]);
        setIsRestart(false);
        setErrorMsg('');
        setFileTz('')
        setIsLoading(false);

    }

    return (
                <Box sx={{ display:'flex', justifyContent: 'right' }}>
                <IconButton onClick={restartEpgViewer} size="medium">
                    <CancelPresentationIcon sx={{ fontSize: 25 }}/>
                </IconButton>
                </Box>
    );
};

export default CloseRestart;
