import {Alert} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

const ErrorMsg = (props) => {

        return (
                <Box sx={{display: 'flex',justifyContent: 'center', m: 2}}>
                    <Alert severity="error">Error: {props.children}</Alert>
                </Box>
        )
    }


export default ErrorMsg;