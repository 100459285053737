import Box from "@mui/material/Box";
import * as React from "react";

export default function Content (props) {

    return (
        <Box component="main" sx={{ flexGrow: 1, height: '100vh', width: '100%', display: 'flex', position: 'relative', pt: 10}}>

            {props.children}

        </Box>
    );
}
