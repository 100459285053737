import React from "react";
import Box from "@mui/material/Box";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MenuContext from "./context/LayoutContext";
import TopBar from "./layout/TopBar";
import SideBar from "./layout/SideBar";
import Content from "./layout/Content";
import EpgTz from "./components/epgviewer/EpgTz";
import EpgViewer from "./pages/EpgViewer";
import Container from "@mui/material/Container";
import EpgViewerContext from "./context/EpgViewerContext";
import { createTheme, ThemeProvider } from '@mui/material/styles';


function App() {
    const theme = createTheme({
        palette: {
            background: {
                default: "red"
            }
        }
    });

    return (
        <BrowserRouter>
            <header/>

                <Box sx={{display: 'flex'}}>
                <MenuContext>
                    <TopBar/>
                    <SideBar/>
                </MenuContext>
                <Content>
                    <Routes>
                        <Route path="/" element={<EpgViewerContext><EpgViewer/></EpgViewerContext>}/>
                        <Route path="/test" element={<EpgTz/>}/>
                        <Route path="/test2" element={<Container sx={{backgroundColor: 'white'}}>testttt</Container>}/>
                    </Routes>
                </Content>
                {/*<Footer/>*/}
            </Box>

        </BrowserRouter>
    );
}

export default App;
