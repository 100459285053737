import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {useFileUrlContext} from "../../context/EpgViewerContext";
dayjs.extend(customParseFormat);


function ShowEpg(props) {

    const { displayTime } = useFileUrlContext();

    // getting EPG Data from props
    let epgData = props.epg;

    const progressBarPercentage = (start, end) => {

        //parse start and end
        let startTime = dayjs(start).unix();
        let endTime = dayjs(end).unix();
        let currentTime = dayjs(displayTime).unix();

        //percentage formula
        let percentage = ((currentTime - startTime) * 100) / (endTime - startTime);

        //prevent over percentage overlapping
        if(percentage > 100) {
            percentage = 100;
        } else if(percentage < 0 ){
            percentage = 0;
        }

        return percentage + '%';
    }

    return (
        <Fade in={true} timeout={500} >
                    <TableContainer component={Paper} variant={'outlined'} variant="elevation" elevation={2} >
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: 'WhiteSmoke'}}>
                                    <TableCell><Typography variant="button">EPG id</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="button">Current Show</Typography></TableCell>
                                    <TableCell align="center"><Typography variant="button">Show Time</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {epgData.map((data, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {data.channel}
                                        </TableCell>
                                        <TableCell align="left">{data.show}</TableCell>
                                        <TableCell align="center" sx={{width: '16vh'}}>

                                            <Box sx={{backgroundColor:"rgb(235 235 235)", position: 'relative', height: '20px', borderRadius: 1}}>
                                                <Box sx={{ height: '100%', left: 5, right: 5, top: 1, position: 'absolute'}}>
                                                    <Box justifyContent="space-between" alignContent="center" sx={{display: 'flex',  alignItems: 'center', height: '100%'}}>
                                                        <Typography variant="button">{dayjs(data.start).format('HH:mm')}</Typography>
                                                        <Typography variant="button">{dayjs(data.end).format('HH:mm')}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box width={progressBarPercentage(data.start, data.end)} sx={{ backgroundColor: '#bcc1ff', height: '100%', borderRadius: 1}}>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
        </Fade>
    );
}

export default ShowEpg;
