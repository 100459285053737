import {Switch} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {useFileUrlContext} from "../../context/EpgViewerContext";
import Box from "@mui/material/Box";

// NoEPG switch under Settings component
function NoEpgButton(props) {

    let {showNoEPG, setShowNoEPG} = useFileUrlContext();

    return (
        <Paper variant="outlined" sx={{ display: 'flex', justifyContent: 'center',  minWidth: '220px', alignItems: 'center', height: 85}}>
            <Box sx={{ pr: 1, pl: 1}}>
                <Typography variant="button" color={'red'}>
                    {props.count}
                </Typography>
                <Typography variant="overline" > channels no EPG</Typography>
            </Box>
            <Box>
                <Switch checked={showNoEPG} size="small" onChange={(e) =>  {setShowNoEPG(e.target.checked)}}/>
        </Box>
        </Paper>
    );
}

export default NoEpgButton;
