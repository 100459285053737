import {IconButton} from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import {useFileUrlContext} from "../../context/EpgViewerContext";
import {Fragment} from "react";

export default function RestTzTimeNow() {
    const {setDisplayTime, setSourceTz, setUpdateEpg, setResetTzTime, resetTzTime} = useFileUrlContext();

    //resetting Epg Time Zone and Time Now
    const timeResetTz = () => {
        setDisplayTime(Date.now())
        setSourceTz('file')
        setUpdateEpg(true)
        setResetTzTime(false)
    }

    return(
        <Fragment>
            {resetTzTime && <IconButton color="primary" aria-label="upload picture" component="span" onClick={timeResetTz} size="small" sx={{ justifyContent: 'right'}}>
            <RefreshIcon color="warning" sx={{ fontSize: 25 }}/>
            </IconButton>}
        </Fragment>
    )

}
