import React, {useEffect, useState} from 'react';
import {Paper} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {TimePicker} from "@mui/lab";
import {TextField} from "@material-ui/core";
import moment from "moment-timezone";
import {useFileUrlContext} from "../../context/EpgViewerContext";
import {useInterval} from "usehooks-ts";

// Time to check for current shows
function EpgShowTime() {

    const {setDisplayTime, displayTime, setUpdateEpg, setResetTzTime, resetTzTime, epgData, noEPG, updateEpg} = useFileUrlContext();
    const [updateCurrentTime, setUpdateCurrentTime] = useState(true);

    const [modified, setModified] = useState(false);


    //change time to custom timing
    const timeChange = (newTime) => {
        setDisplayTime(newTime);
        setUpdateCurrentTime(false);
        setResetTzTime(true)
        if(epgData.length > 0 || noEPG.length > 0) {
            setModified(true);
            setUpdateEpg(true);
            selectedBox()
        }
    };
    //update the time every 30 seconds when not manual
    useInterval(() => {
            setDisplayTime(Date.now());
        },
        // Delay in milliseconds or null to stop it
        updateCurrentTime ? 30000 : null,
    )

    //highlight the box after changing Time Zone
    function selectedBox() {
        let borderColor= null
        if((updateEpg && modified) || (!resetTzTime && updateEpg)) {
            borderColor = {borderColor:'#ff9800' }
        }
        return borderColor
    }

    useEffect( () => {

        epgData.length > 0 || noEPG.length > 0 ? setUpdateCurrentTime(false) : setUpdateCurrentTime(true);

        if(!updateEpg && modified)
            setModified(false)

    }, [updateEpg, modified, epgData]);

    return (
        <Paper variant="outlined" sx={{ backgroundColor: selectedBox, display: 'flex', justifyContent: 'center',  minWidth: '220px', alignItems: 'center', height: 85}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker label="Time Now" value={displayTime}
                                    onChange={timeChange} variant="filled"
                                    inputProps={{ style: { textAlign: "center", color: '#1976d2' }}}
                                    renderInput={ (params) =>
                                        <TextField {...params} helperText={"("+ moment().format('Z')+") "+moment.tz.guess()}/>} />
                    </LocalizationProvider>
         </Paper>
    );
}

export default EpgShowTime;
