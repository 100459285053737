import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useMenuContext} from "../context/LayoutContext";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PreviewIcon from '@mui/icons-material/Preview';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import Image from 'mui-image';
import SpLogo from '../static/images/sptoolslogo.png';

const drawerWidth = 220;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const SideBar = () => {
    const theme = useTheme();

    let {open, setOpen} = useMenuContext();
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box>

            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <Box>
                        <Image src={SpLogo}/>
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List sx={{pt: 0}}>
                    <ListItem button selected sx={{pl: '20px'}}>
                        <ListItemIcon>
                            <PreviewIcon/>
                        </ListItemIcon>
                        <ListItemText primary="EPG Viewer"/>
                    </ListItem>
                    <ListItem button disabled sx={{pl: '20px'}}>
                        <ListItemIcon>
                            <NoteAddIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Epg Creator"/>
                    </ListItem>
                    <ListItem button disabled sx={{pl: '20px'}}>
                        <ListItemIcon>
                            <LiveTvIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Stream Tester"/>
                    </ListItem>
                </List>
                <Divider/>
            </Drawer>
        </Box>
    );
}

export default SideBar;
