import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Container from '@mui/material/Container';
import FileTab from "./FileTab";
import UrlTab from "./UrlTab";
import Fade from '@mui/material/Fade';


// container for URL and File Tabs
export default function FileUrlTabs() {

    //change between two tabs
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [value, setValue] = useState('1');


    return (
        <Fade in={true}>
            <Container maxWidth="md" sx={{mt: 4}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 3}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="URL" value="1"/>
                            <Tab label="File" value="2"/>
                        </TabList>
                    </Box>

                    {/*Url Part */}
                    <TabPanel value="1">
                        <UrlTab/>
                    </TabPanel>


                    {/*File Uploader*/}
                    <TabPanel value="2">
                        <FileTab/>
                    </TabPanel>
                </TabContext>
            </Container>
        </Fade>
    );
}
