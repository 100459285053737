import React, {createContext, useContext, useState} from 'react';

const layoutContext = createContext();

const LayoutContext = ({children}) => {

    const [open, setOpen] = useState(true);

    return (
        <layoutContext.Provider value={{ open, setOpen }}>
            {children}
        </layoutContext.Provider>
    );


}

export const useMenuContext = ()=> useContext(layoutContext);

export default LayoutContext;
