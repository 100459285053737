import React, {createContext, useContext, useState} from 'react';

const epgViewerContX = createContext();

const EpgViewerContext = ({children}) => {
    const [epgUrl, setEpgUrl] = useState('');
    const [file, setFile] = useState(null);
    const [epgData, setEpgData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRestart, setIsRestart] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [noEPG, setNoEPG] = useState([]);
    const [showNoEPG, setShowNoEPG] = useState(false);
    const [displayTime, setDisplayTime] = useState(new Date());
    const [sourceTz, setSourceTz] = useState('file');
    const [epgInfo, setEpgInfo] = useState({});
    const [fileTz, setFileTz] = useState('');
    const [updateEpg, setUpdateEpg] = useState(false);
    const [resetTzTime, setResetTzTime] = useState(false);
    const [apiPostQuery, setApiPostQuery] = useState();


    return (
        <epgViewerContX.Provider value={{
            epgUrl, setEpgUrl,
            file, setFile,
            epgData,
              setEpgData,
            isLoading, setIsLoading,
            errorMsg, setErrorMsg,
            isRestart, setIsRestart,
            noEPG, setNoEPG,
            showNoEPG, setShowNoEPG,
            sourceTz, setSourceTz,
            fileTz, setFileTz,
            displayTime, setDisplayTime,
            updateEpg, setUpdateEpg,
            resetTzTime, setResetTzTime,
            apiPostQuery, setApiPostQuery,
            epgInfo, setEpgInfo
            }}>
            {children}
        </epgViewerContX.Provider>
    );
};

export const useFileUrlContext = ()=> useContext(epgViewerContX);

export default EpgViewerContext;
