import {useEffect} from 'react';
import FileSourceTz from "../js/FileSourceTz";
import axios from "axios";
import {useFileUrlContext} from "../context/EpgViewerContext";

function useEpgViewerAPI() {

    const {
        setIsLoading,
        setEpgData,
        setErrorMsg,
        setNoEPG,
        setFileTz, apiPostQuery, setEpgInfo
    } = useFileUrlContext();

    //switch to local API request if needed
    const dev = false;
    let fetchURL = "https://sptools.setplex.net/api/epg"
    if (dev) fetchURL = "http://localhost:5000/epg";

    //axios request config options
    const config = {
        method: 'post',
        url: fetchURL,
        crossDomain: true,
        headers: {
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Origin': '*',
        },
        // data needed to post
        data: apiPostQuery
    }
    const fetchData = async () => {
        try {
            let response = null
            setErrorMsg('')
            //run only if epgURL exists
            if (apiPostQuery && apiPostQuery.epgUrl) {
                setIsLoading(true)
                response = await axios(config);
                if (response.data) {
                    //assign returned data from API
                    setEpgData(response.data.showsFound)
                    setNoEPG(response.data.noShows)
                    setFileTz(FileSourceTz(response.data.sourceTz))
                    setEpgInfo(response.data.info)
                }
            }
            setIsLoading(false);

        } catch (e) {
            setErrorMsg(e.message)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchData();

    }, [apiPostQuery]);
}

export default useEpgViewerAPI;
