import * as React from "react";
import Box from "@mui/material/Box";
import Settings from "../components/epgviewer/Settings";
import FileUrlTabs from "../components/epgviewer/FileUrlTabs";
import Actions from "../components/epgviewer/Actions";
import Loading from "../components/epgviewer/Loading";
import ErrorMsg from "../components/epgviewer/ErrorMsg";
import NoEpgShow from "../components/epgviewer/NoEpgShow";
import ShowEpg from "../components/epgviewer/ShowEpg";
import {useFileUrlContext} from "../context/EpgViewerContext";
import useEpgViewerAPI from "../hooks/useEpgViewerAPI";
import InfoItems from "../components/epgviewer/InfoItems";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";

export default function EpgViewer() {

    const { isLoading, epgData, errorMsg, noEPG, } = useFileUrlContext();

    //mount EPG API fetch hook
    useEpgViewerAPI();

    return (
        <Container sx={{mt: 3, pb: 3, mb: 4}}>
            <Settings/>
            <Actions/>

            {isLoading && <Loading /> }
            {/*<FileUrlTabs/>*/}
            {(epgData.length === 0 && !isLoading) && (noEPG.length === 0 && !isLoading) && <FileUrlTabs/>}
            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
            {((epgData.length > 0 && !isLoading) || (noEPG.length > 0 && !isLoading)) && <InfoItems/> }
            {(noEPG.length > 0 && !isLoading) && <NoEpgShow noEpg={noEPG} />}
            {(epgData.length > 0 && !isLoading) && <ShowEpg epg={epgData}/> }
            <Divider sx={{ width: '10px', height: '20px'}}/>
        </Container>
    );
}
