import React, {useEffect, useState} from 'react';
import { Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import timeZoneArr from "../../js/TimeZoneArr";
import {useFileUrlContext} from "../../context/EpgViewerContext";

function EpgTz() {

    const {setSourceTz, sourceTz, epgData, noEPG, fileTz, setUpdateEpg, updateEpg, resetTzTime, setResetTzTime} = useFileUrlContext();
    const [modified, setModified] = useState(false);

    //change Time Zone to hours instead of 'FILE'
    const handleChange = (event) => {
        setSourceTz(event.target.value)
        setResetTzTime(true)

        if(epgData.length > 0 || noEPG.length > 0) {
            setModified(true)
            setUpdateEpg(true)
            focusBox();
        }
    };

    //highlight the box when time zone is manual
    const focusBox = () => {
        let borderColor= null
        if((updateEpg && modified) || (!resetTzTime && updateEpg)) {
            borderColor = {borderColor:'#ff9800' }
        }
        return borderColor
    }

    useEffect( () => {
        if(!updateEpg && modified){
            setModified(false)
        }
    }, [updateEpg, modified]);

    return (
        <Paper variant="outlined" sx={{ borderColor: focusBox, display: 'flex', justifyContent: 'center', minWidth: '250', height: 85, alignItems: 'center'}}>
            <FormControl>
                <InputLabel id="demo-simple-select-helper-label">Source Timezone</InputLabel>
                <Select  labelId="demo-simple-select-helper-label" id="demo-simple-select-helper"
                         value={sourceTz} label="EPG File Source TimeZone" onChange={handleChange}>
                    <MenuItem value={'file'} dense >
                        <Typography variant="inherit" sx={{width: 160, color: '#1976d2'}}>Get from file</Typography>
                    </MenuItem>
                    {timeZoneArr.map((item, i) =>
                        <MenuItem key={i} value={item.offset} dense>
                            <Typography variant="inherit" noWrap sx={{width: 160, color: '#1976d2' }}>{item.label}</Typography>
                        </MenuItem> )}
                </Select>
                <FormHelperText style={{ color: '#c62828', justifyContent: 'left', marginLeft: 0, marginTop: '3px', maxWidth: 170, whiteSpace: 'nowrap',  overflowX: 'hidden', textOverflow: "ellipsis"}} margin="dense" variant="filled" size="small" >{fileTz}</FormHelperText>
            </FormControl>
        </Paper>
    );
}

export default EpgTz;
