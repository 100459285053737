import React from 'react';
import {useDropzone} from "react-dropzone";
import Container from "@mui/material/Container";
import {CssBaseline} from "@mui/material";

// File upload only
function FileTab() {

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    //listing uploaded files
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <Container  sx={{ display: 'flex', width: 500, justifyContent: 'center', textAlign: 'center', border: 1, borderColor: 'primary.main', borderRadius: 2 }}>
            <CssBaseline/>
            <section>
                <div{...getRootProps()} >
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <aside >
                    <h4>Files</h4>
                    <ul>{files}</ul>
                </aside>
            </section>
        </Container>
    );
}

export default FileTab;
