import Container from "@mui/material/Container";
import CloseRestart from "./CloseRestart";
import {Fragment} from "react";
import {useFileUrlContext} from "../../context/EpgViewerContext";
import UpdateEpg from "./UpdateEpg";
import RestTzTimeNow from "./ResetTzTimeNow";
import InfoItems from "./InfoItems";
import Box from "@mui/material/Box";

// where Update, Restart and Close will appear
export default function Actions()  {

    let { epgData, noEPG, isLoading } = useFileUrlContext();


    return(
        <Fragment>
            {((epgData.length > 0 ||  noEPG.length > 0) && !isLoading) &&
        <Box sx={{ display: 'flex',  justifyContent: 'right', mt: 3, alignItems: 'center', pr: 0}}>
                <UpdateEpg/>
               <RestTzTimeNow/>
                <CloseRestart/>
        </Box>
            }
        </Fragment>
    )

}
