import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import {CssBaseline} from "@mui/material";

export default function Loading() {

return(
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', height: 300 }}>
        <CircularProgress  size={68} />
        <CssBaseline/>
    </Box>
);
}
