import Box from "@mui/material/Box";
import {Chip, Slide} from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {useFileUrlContext} from "../../context/EpgViewerContext";

// EPG info and detailing component
export default function InfoItems(){
    const { epgInfo } = useFileUrlContext();
    // assign API data to local vars
    let infos = {
        fileSize: (epgInfo.fileSize / 1048576).toFixed(2)+' MB File Size',
        showsCount: epgInfo.showsCount +' Channels with EPG',
        channelsNoEPG: epgInfo.channelsNoEPG +" Channels without EPG",
        totalShows: epgInfo.totalShows +" Total Shows",
        generationTime: ((epgInfo.generationTime / 1000).toFixed(0) === '0' ? 1 : (epgInfo.generationTime / 1000).toFixed(0)) +"s Generated Time"
    }

    //hide and show on i click
    const [showInfo, setShowInfo] = useState(true);
    function handleShow() {
        (showInfo ? setShowInfo(false) : setShowInfo(true))
    }

    return(
        <Box  variant="outlined" elevation={2} sx={{ display: 'flex', width: '100%', alignItems: 'center', mb: 1, mt: 1}}>
            <Slide direction="right" in={showInfo} mountOnEnter unmountOnExit>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-around'}}>
                    <Chip size="small" icon={<FileDownloadOutlinedIcon />} label={infos.fileSize} variant="filled"/>
                    <Chip size="small" icon={<VisibilityOutlinedIcon />} label={infos.showsCount} variant="filled" />
                    <Chip size="small" icon={<VisibilityOffOutlinedIcon />} label={infos.channelsNoEPG} variant="filled"/>
                    <Chip size="small" icon={<FormatLineSpacingIcon />} label={infos.totalShows} variant="filled"/>
                    <Chip size="small" icon={<DownloadingOutlinedIcon />} label={infos.generationTime} variant="filled"/>
                </Box>
            </Slide>
            <Box sx={{ display: 'flex', justifyContent: 'right', alignSelf: 'right'}}>
                <IconButton onClick={handleShow}>
                    <InfoOutlinedIcon disabled color="primary" />
                </IconButton>
            </Box>
        </Box>
    )
}
